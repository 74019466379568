import React, { useState } from 'react';
import MultiSelectDropdown from './MultiSelectDropdown';
import StoryModal from './StoryModal';
import successStoriesData from '../../data/success-stories-db.json';
import useResizer from '@components/extra/useResizer';

// const allStories = [
//   {
//     name: 'Ashwin Verma',
//     position: 'SDE 1 at Zoho',
//     program: 'Fellowship Program in QA Automation (SDET)',
//     imageUrl: '/images/ashwin1.jpg',
//     transition: 'Developer',
//     experience: '0-2 Years',
//     package: '7-10 LPA',
//     hike: '91%',
//     videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
//     quote:
//       'At Crio, you learn how projects are built in companies. The learning is fast-paced yet structured. The project modules helped me immensely during interviews.',
//   },
//   {
//     name: 'Priya Sharma',
//     position: 'QA Engineer at Freshworks',
//     program: 'Manual + Automation',
//     imageUrl: '/images/priya.jpg',
//     transition: 'Tester',
//     experience: '3-5 Years',
//     package: '5-7 LPA',
//     hike: '40%',
//   },
//   {
//     name: 'Rahul Mehta',
//     position: 'SDET at TCS',
//     program: 'QA Automation',
//     imageUrl: '/images/rahul.jpg',
//     transition: 'Support',
//     experience: '5+ Years',
//     package: '10+ LPA',
//     hike: '35%',
//   },
//   {
//     name: 'Sneha Kapoor',
//     position: 'Test Analyst at Cognizant',
//     program: 'SDET',
//     imageUrl: '/images/sneha.jpg',
//     transition: 'Non-tech',
//     experience: '0-2 Years',
//     package: '5-7 LPA',
//     hike: '50%',
//   },
//   {
//     name: 'Vikram Iyer',
//     position: 'QA Engineer at Accenture',
//     program: 'Manual + Automation',
//     imageUrl: '/images/vikram.jpg',
//     transition: 'Tester',
//     experience: '3-5 Years',
//     package: '7-10 LPA',
//     hike: '20%',
//   },
//   {
//     name: 'Anjali Rao',
//     position: 'Automation Engineer at Wipro',
//     program: 'Test Mastery',
//     imageUrl: '/images/anjali.jpg',
//     transition: 'Developer',
//     experience: '0-2 Years',
//     package: '<5 LPA',
//     hike: '10%',
//   },
//   {
//     name: 'Kunal Desai',
//     position: 'SDET 2 at Google',
//     program: 'QA Automation',
//     imageUrl: '/images/kunal.jpg',
//     transition: 'Support',
//     experience: '5+ Years',
//     package: '10+ LPA',
//     hike: '60%',
//   },
//   {
//     name: 'Meera Nair',
//     position: 'QA at Infosys',
//     program: 'SDET',
//     imageUrl: '/images/meera.jpg',
//     transition: 'Non-tech',
//     experience: '3-5 Years',
//     package: '5-7 LPA',
//     hike: '25%',
//   },
//   {
//     name: 'Siddharth Patel',
//     position: 'QA Intern at Flipkart',
//     program: 'Manual + Automation',
//     imageUrl: '/images/sid.jpg',
//     transition: 'Developer',
//     experience: '0-2 Years',
//     package: '<5 LPA',
//     hike: '15%',
//   },
//   {
//     name: 'Isha Khan',
//     position: 'SDET at Capgemini',
//     program: 'Test Mastery',
//     imageUrl: '/images/isha.jpg',
//     transition: 'Tester',
//     experience: '3-5 Years',
//     package: '7-10 LPA',
//     hike: '70%',
//   },
// ];

const filters = {
  transition: [
    'Fresher Transitions',
    'Non-Circuit Branch to IT Jobs',
    'Non-IT to IT (Experienced Profiles)',
    'Service to Product',
    'Testing to Development',
    'Manual to Automation',
    'Career Gap',
  ],
  experience: [
    'Fresher',
    'Between 1-3 Years',
    'Between 3-5 Years',
    'More than 5 Years',
  ],
  program: [
    'Full-Stack Development',
    'Backend Development',
    'QA Automation',
    'Data Analytics',
  ],
  package: [
    'Less than 5 LPA',
    'More than 5 LPA',
    'More than 10 LPA',
    'More than 15 LPA',
  ],
};

const SuccessStories = () => {
  const allStories = successStoriesData.strapi_success_stories.map((story) => {
    const {
      Name,
      Transition,
      Salary,
      Program,
      Experience,
      Company,
      Role,
      HikePercentage,
      TestimonialLink,
      company_logo,
      profile_picture,
    } = story.attributes || {};

    return {
      name: Name,
      transition: Transition,
      package: Salary,
      program: Program,
      experience: Experience,
      company: Company,
      position: Role,
      hike: HikePercentage,
      videoUrl: TestimonialLink,
      companyLogoUrl: company_logo,
      imageUrl: profile_picture,
    };
  });
  console.log('🚀 ~ allStories ~ allStories:', allStories);

  const isMobile = useResizer();

  const [selectedStory, setSelectedStory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedFilters, setSelectedFilters] = useState({
    transition: [],
    experience: [],
    program: [],
    package: [],
  });

  const pageSize = isMobile ? 3 : 6;

  const filteredStories = allStories.filter((story) => {
    return (
      (!selectedFilters.transition.length ||
        selectedFilters.transition.includes(story.transition)) &&
      (!selectedFilters.experience.length ||
        selectedFilters.experience.includes(story.experience)) &&
      (!selectedFilters.program.length ||
        selectedFilters.program.includes(story.program)) &&
      (!selectedFilters.package.length ||
        selectedFilters.package.includes(story.package))
    );
  });

  const totalPages = Math.ceil(filteredStories.length / pageSize);
  const paginatedStories = filteredStories.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  return (
    <div className="my-10 flex flex-col items-center gap-8 bg-v5-neutral-200 py-10 px-4">
      <h2 className="text-2xl font-semibold">Success Stories</h2>

      {/* Filters */}
      <div className="flex flex-wrap items-center justify-center gap-4">
        <h3 className="font-manrope text-[14px] font-bold text-v5-neutral-400 md:text-[20px]">
          Filter by:
        </h3>

        <MultiSelectDropdown
          label="Transition"
          options={filters.transition}
          selectedValues={selectedFilters.transition}
          onChange={(values) =>
            setSelectedFilters((prev) => ({ ...prev, transition: values }))
          }
        />
        <MultiSelectDropdown
          label="Experience Level"
          options={filters.experience}
          selectedValues={selectedFilters.experience}
          onChange={(values) =>
            setSelectedFilters((prev) => ({ ...prev, experience: values }))
          }
        />
        <MultiSelectDropdown
          label="Programs Chosen"
          options={filters.program}
          selectedValues={selectedFilters.program}
          onChange={(values) =>
            setSelectedFilters((prev) => ({ ...prev, program: values }))
          }
        />
        <MultiSelectDropdown
          label="Package"
          options={filters.package}
          selectedValues={selectedFilters.package}
          onChange={(values) =>
            setSelectedFilters((prev) => ({ ...prev, package: values }))
          }
        />
      </div>

      {/* Grid */}
      {paginatedStories.length > 0 ? (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
          {paginatedStories.map((story, index) => (
            <div
              key={index}
              className="max-w-[380px] overflow-hidden rounded-[12px] bg-white shadow-md md:min-h-[330px] md:w-[380px]"
            >
              <div className="relative h-[160px] w-full bg-slate-600">
                {story.hike > 0 && (
                  <div className="absolute right-0 flex items-center gap-1 rounded-bl-[12px] rounded-tr-[12px] bg-v5-green-300 px-[12px] py-1 text-center font-rubik text-[12px] font-semibold text-white shadow-md">
                    <div className="h-[14px] w-[14px]">
                      <img
                        src={`/images/HikeIcon.png`}
                        alt={story.name}
                        className="h-full w-full object-contain "
                      />
                    </div>
                    {story.hike}% salary hike after Crio
                  </div>
                )}
                {story.imageUrl ? (
                  <img
                    src={`/images/strapi/success-stories/${story.imageUrl}`}
                    alt={story.name}
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <img
                    src={`/images/ProfilePlaceholder.png`}
                    alt={story.name}
                    className="h-full w-full object-cover"
                  />
                )}
                {story.companyLogoUrl && (
                  <div className="absolute left-1/2 -mt-[20px] h-[40px] w-[140px] -translate-x-1/2 rounded-[12px] border-[1px] border-v5-neutral-300 bg-white">
                    <img
                      src={`/images/strapi/success-stories/${story.companyLogoUrl}`}
                      alt={story.name}
                      className="h-full w-full object-contain "
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center py-3 px-2 pt-[28px] text-center">
                <p className="text-[16px] font-semibold text-gray-700">
                  {story.name}
                </p>
                <p className="text-[12px] text-gray-400">
                  {story.position} at{' '}
                  <span className="font-semibold">{story.company}</span>
                </p>
                <p className="mt-4 text-[10px] text-gray-400">Enrolled in</p>
                <p className="text-[12px] font-semibold text-gray-600">
                  {story.program}
                </p>
              </div>
              {story.videoUrl && (
                <div
                  className="flex h-[40px] w-full cursor-pointer items-center justify-center gap-1 border-t"
                  onClick={() => setSelectedStory(story)}
                >
                  <div className="h-[14px] w-[14px]">
                    <img
                      src={`/images/PlayIcon.png`}
                      alt={story.name}
                      className="h-full w-full object-contain "
                    />
                  </div>
                  <p className="text-[12px] text-gray-700">View Story</p>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center py-10 text-center text-sm text-gray-500">
          No success stories found.
        </div>
      )}

      {/* Pagination */}
      <div className="mt-4 flex items-center gap-2">
        {/* First page button */}
        {currentPage > 3 && (
          <>
            <button
              onClick={() => setCurrentPage(1)}
              className={`flex h-8 w-8 items-center justify-center rounded-full ${
                currentPage === 1
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              1
            </button>
            <span className="text-gray-400">...</span>
          </>
        )}

        {/* Page window: currentPage ± 2 */}
        {Array.from({ length: totalPages }).map((_, i) => {
          const pageNumber = i + 1;
          const isInRange =
            pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2;

          return isInRange ? (
            <button
              key={i}
              onClick={() => setCurrentPage(pageNumber)}
              className={`flex h-8 w-8 items-center justify-center rounded-full ${
                currentPage === pageNumber
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              {pageNumber}
            </button>
          ) : null;
        })}

        {/* Last page button */}
        {currentPage < totalPages - 2 && (
          <>
            <span className="text-gray-400">...</span>
            <button
              onClick={() => setCurrentPage(totalPages)}
              className={`flex h-8 w-8 items-center justify-center rounded-full ${
                currentPage === totalPages
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              {totalPages}
            </button>
          </>
        )}
      </div>

      {/* Modal */}
      <StoryModal
        story={selectedStory}
        onClose={() => setSelectedStory(null)}
      />
    </div>
  );
};

export default SuccessStories;
