import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ImageMarquee from './ImageMarquee';

const stats = [
  {
    label: '95%',
    sublabel: 'placed within 9 months of graduation',
    bg: 'bg-yellow-200',
  },
  { label: '81%', sublabel: 'Average Salary Hike', bg: 'bg-green-200' },
  { label: '1000+', sublabel: 'Hiring Partners', bg: 'bg-teal-200' },
];

const HeroSection = () => {
  const marqueeRef = useRef(null);

  useEffect(() => {
    let animationFrame;
    const scroll = () => {
      if (marqueeRef.current) {
        marqueeRef.current.scrollLeft += 0.5;
        if (
          marqueeRef.current.scrollLeft >=
          marqueeRef.current.scrollWidth / 2
        ) {
          marqueeRef.current.scrollLeft = 0;
        }
      }
      animationFrame = requestAnimationFrame(scroll);
    };
    animationFrame = requestAnimationFrame(scroll);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  // Column content reused
  const renderColumns = () => (
    <>
      {/* Column 1 */}
      <div className="flex flex-col items-center gap-4">
        <StaticImage
          src="../../images/PlacementsPage/HeroProfile1.png"
          alt="Learner 1"
          className="h-40 w-40 rounded-xl object-cover"
        />
        <div className="flex h-40 w-40 flex-col items-center justify-center rounded-xl bg-green-200 text-center">
          <p className="text-2xl font-bold">81%</p>
          <p className="mt-1 px-2 text-sm text-gray-700">Average Salary Hike</p>
        </div>
        <StaticImage
          src="../../images/PlacementsPage/HeroProfile4.png"
          alt="Learner 4"
          className="h-40 w-40 rounded-xl object-cover"
        />
      </div>

      {/* Column 2 */}
      <div className="flex flex-col items-center gap-4 pt-10">
        <div className="flex h-40 w-40 flex-col items-center justify-center rounded-xl bg-yellow-200 text-center">
          <p className="text-2xl font-bold">95%</p>
          <p className="mt-1 px-2 text-sm text-gray-700">
            placed within 9<br /> months of graduation
          </p>
        </div>
        <StaticImage
          src="../../images/PlacementsPage/HeroProfile2.png"
          alt="Learner 2"
          className="h-40 w-40 rounded-xl object-cover"
        />
        <StaticImage
          src="../../images/PlacementsPage/HeroProfile5.png"
          alt="Learner 5"
          className="h-40 w-40 rounded-xl object-cover"
        />
      </div>

      {/* Column 3 */}
      <div className="flex flex-col items-center gap-4">
        <StaticImage
          src="../../images/PlacementsPage/HeroProfile1.png"
          alt="Learner 1 Repeat"
          className="h-40 w-40 rounded-xl object-cover"
        />
        <StaticImage
          src="../../images/PlacementsPage/HeroProfile3.png"
          alt="Learner 3"
          className="h-40 w-40 rounded-xl object-cover"
        />
        <div className="flex h-40 w-40 flex-col items-center justify-center rounded-xl bg-teal-200 text-center">
          <p className="text-2xl font-bold">1000+</p>
          <p className="mt-1 px-2 text-sm text-gray-700">Hiring Partners</p>
        </div>
      </div>

      {/* Column 4 */}
      <div className="flex flex-col items-center gap-4 pt-10">
        <StaticImage
          src="../../images/PlacementsPage/HeroProfile6.png"
          alt="Learner 6"
          className="h-40 w-40 rounded-xl object-cover"
        />
        <div className="flex h-40 w-40 flex-col items-center justify-center rounded-xl bg-green-200 text-center">
          <p className="text-2xl font-bold">81%</p>
          <p className="mt-1 px-2 text-sm text-gray-700">Average Salary Hike</p>
        </div>
        <StaticImage
          src="../../images/PlacementsPage/HeroProfile3.png"
          alt="Learner 3 Again"
          className="h-40 w-40 rounded-xl object-cover"
        />
      </div>
    </>
  );

  return (
    <section className="mt-[80px] flex flex-col gap-8 md:mx-auto md:h-[600px] md:w-[1200px] md:flex-row md:items-center">
      {/* Left Section */}
      <div className="w-[100dvw] text-center md:w-1/2 md:text-left">
        <h1 className="mb-4 text-[24px] font-bold leading-tight md:text-4xl">
          1000+ Learners Placed In Top Companies
        </h1>
        <p className="mx-2 mb-6 text-lg text-gray-700">
          Learn with Real Work-Ex. Join the best product-based companies with{' '}
          <span className="font-semibold text-v5-green-300">
            assured placement
          </span>
          .
        </p>
        <a
          href={'#success-stories'}
          className="rounded-md bg-yellow-400 px-6 py-3 font-semibold text-black shadow-md hover:bg-yellow-500"
        >
          Learn More →
        </a>
      </div>

      <div className="w-full md:w-1/2">
        <ImageMarquee />
      </div>
    </section>
  );
};

export default HeroSection;
